<template>
  <div ref="container" class="keen-slider story-circle hover-colorized" :class="payload.customClass">
    <div
        class="keen-slider__slide flex flex-col items-center justify-center px-2 hover-colorized-item"
         v-for="(item, idx) in payload.content"
         :key="`styb-${idx}`"
    >
      <NuxtLinkLocale :to="item.ctaLink ? checkAndAddSlash(item.ctaLink) : '#'">
        <template v-if="item?.desktopImage">
          <div class="sc-item">
            <NuxtImg
                :src="getImageForDevice(item)"
                width="256"
                height="256"
                :alt="item.title"
                fit="outside"
                loading="lazy"
                format="webp"
                :placeholder="[50, 50, 93, 3]"
                :quality="90"
                class="sc-item-img"
            />
            <template v-if="item.title && !item.config?.titleIsOutbox">
              <div class="absolute z-50 opacity-50 bg-primary-500 h-full w-full"></div>
              <div class="absolute z-100 h-full flex items-center">
                  <span class="text-white uppercase text-xs sm:text-sm font-bold text-center whitespace-normal">
                      {{item.title}}
                  </span>
              </div>
            </template>
          </div>

          <div class="sc-item-text" v-if="item.title && item.config?.titleIsOutbox">
            {{item.title}}
          </div>
        </template>

        <span class="sc-text" v-else>{{item.title}}</span>

      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(['payload'])

const [container] = useKeenSlider({
  loop: true,
  breakpoints: {
    '(min-width: 300px)': {
      slides: { perView: 3, spacing: 10 },
    },
    '(min-width: 380px)': {
      slides: { perView: 3, spacing: 10 },
    },
    '(min-width: 640px)': {
      slides: { perView: 4, spacing: 10 },
    },
    '(min-width: 768px)': {
      slides: { perView: 4, spacing: 10 },
    },
    '(min-width: 1024px)': {
      slides: { perView: 8, spacing: 5 },
    },
  },
  slides: { perView: 4, origin : 'center' },
}, [
  (slider) => {
    let timeout
    let mouseOver = false
    function clearNextTimeout() {
      clearTimeout(timeout)
    }
    function nextTimeout() {
      clearTimeout(timeout)
      if (mouseOver) return
      timeout = setTimeout(() => {
        slider.next()
      }, 5000)
    }
    slider.on("created", () => {
      slider.container.addEventListener("mouseover", () => {
        mouseOver = true
        clearNextTimeout()
      })
      slider.container.addEventListener("mouseout", () => {
        mouseOver = false
        nextTimeout()
      })
      nextTimeout()
    })
    slider.on("dragStarted", clearNextTimeout)
    slider.on("animationEnded", nextTimeout)
    slider.on("updated", nextTimeout)
  },
])

</script>