<template>
  <div class="space-y-2 animate-pulse">
    <div class="w-32 h-32 md:w-64 md:h-64 rounded-lg bg-gray-100"></div>
    <div class="w-32 md:w-64 h-5 bg-gray-100"></div>
    <div class="w-16 md:w-24 h-5 bg-gray-100"></div>
  </div>
</template>

<script setup>
</script>

<style scoped>

</style>