<template>
  <div class="relative">
    <div ref="container"
         class="keen-slider"
         :class="[
            payload.customClass,
            {'from-black/40 via-transparent to-transparent' : payload.config?.text_backdrop_enable}
        ]"
    >
      <div class="keen-slider__slide lazy__slide my-0" v-for="(image, index) in payload.content" :key="`slider${index}`">
        <NuxtLinkLocale
            :to="image?.ctaLink && !image.ctaText ? checkAndAddSlash(image.ctaLink) : ''"
            class="block relative overflow-hidden"
            :class="image?.ctaLink && !image.ctaText ? 'cursor-pointer' : 'cursor-default'"
        >
          <div class="absolute z-30 flex w-full h-full inset-0">
            <div
                class="slider-cta-wrapper"
                :class="[
                  horizontalClass(image.config.titleHPlacement, true),
                  verticalClass(image.config.titleVPlacement, true),
                ]"
            >
              <div class="cta-title" :class="getTextColorClass(image?.config?.titleColor)" v-if="image?.title">
                {{image?.title}}
              </div>
              <div class="cta-description" :class="getTextColorClass(image?.config?.subTitleColor)" v-if="image?.subTitle">
                {{image.subTitle}}
              </div>

              <NuxtLinkLocale :to="image.ctaLink" class="cta-button" v-if="image.ctaText">
                {{image.ctaText}}
              </NuxtLinkLocale>
            </div>
          </div>
          <video
              :class="[imgPositionClass(image.config.imgVPlacement, image.config.imgHPlacement), image.config.imgClass, 'w-100']"
              :id="`slideVideo::${index}`"
              muted
              autoplay
              playsinline
              @play="onVideoPlayed(index)"
              @ended="onVideoEnded(index)"
              v-if="checkFileExtension(getImageForDevice(image)) === 'mp4' || checkFileExtension(getImageForDevice(image)) === 'webm'">
            <source :src="image.desktopImage" type="video/mp4">
            <source :src="image.mobileImage" type="video/webm">
            <p>{{ $t('app.video-not-supported') }}</p>
          </video>
          <!-- Lazy : loaded[index] ? getImageForDevice(image) : '' -->
          <NuxtImg
              :src="getImageForDevice(image)"
              alt=""
              class="w-full object-contain h-full swiper-lazy"
              sizes="sm:512px md:768px lg:992px xl:1200px xxl:1920px"
              densities="x1 x2"
              placeholder
              :quality="80"
              :width="1920"
              fit="outside"
              format="webp"
              loading="lazy"
              :class="[imgPositionClass(image.config.imgVPlacement, image.config.imgHPlacement), image.config.imgClass]"
              v-else
          />
        </NuxtLinkLocale>
      </div>
    </div>

    <UINavigationArrows :slider="slider" :current="current" v-if="props.payload.config?.show_navigation_arrows"/>

    <div v-if="slider && slides?.length > 1" class="dots">
      <button
          v-for="(_slide, idx) in dotHelper"
          @click="slider.moveToIdx(idx)"
          :class="{ dot: true, active: current === idx }"
          :key="idx"
      ></button>
    </div>
  </div>
</template>

<script setup>
import {checkFileExtension} from "~/composables/useHelper";

const props = defineProps(['payload'])
const slides = computed(() => props.payload.content)

const autoplayDuration = computed(() => {
  if (props.payload?.config?.autoplay_duration) {
    return parseInt(props.payload?.config?.autoplay_duration)
  } else {
    return 5000
  }
})

const current = ref(1)
const loaded = ref([true]);

const currentSlide = computed(() => slides.value[current.value])
const isVideo = computed(() => checkFileExtension(currentSlide.value?.desktopImage) === 'mp4' || checkFileExtension(currentSlide.value?.desktopImage) === 'webm');

const timeout = ref()
const mouseOver = ref(false)

const plugins = []
const autoPlayPlugin = (slider) => {
  slider.on("created", () => {
    slider.container.addEventListener("mouseover", () => {
      mouseOver.value = true
      clearNextTimeout()
    })
    slider.container.addEventListener("mouseout", () => {
      mouseOver.value = false
      nextTimeout()
    })
    nextTimeout()
  })
  slider.on("dragStarted", clearNextTimeout)
  slider.on("animationEnded", nextTimeout)
  slider.on("updated", nextTimeout)
}

// Eğer 1'den fazla slayt var ise autoplay çalıştırıyoruz
if (slides.value?.length > 1) {
  plugins.push(autoPlayPlugin)
}

const [container, slider] = useKeenSlider({
  loop: true,
  initial: 0,
  slides: {
    perView: 1,
    spacing: 15,
  },
  // For lazy loading
  animationEnded: (s) => {
    const idx = s.track.details.rel;
    loaded.value[idx] = true;
  },
  created : (s) => {
    current.value = s.track.details.rel
  },
  slideChanged: (s) => {
    current.value = s.track.details.rel

    const video = document.getElementById(`slideVideo::${s.track.details.rel}`)

    if (typeof video !== 'undefined' && isVideo.value) {
      // swiperHeight.value = video.videoHeight
      //video.currentTime = 0;
      video.play()
    }

  },
}, plugins);

function clearNextTimeout() {
  clearTimeout(timeout.value)
}
function nextTimeout() {
  clearTimeout(timeout.value)

  if (mouseOver.value) return;

  timeout.value = setTimeout(() => {
    if (!isVideo.value) slider.value.next()
  }, autoplayDuration.value)
}

const dotHelper = computed(() => slider.value ? [...Array(slider.value.track.details.slides.length).keys()] : [])

const onVideoEnded = (index) => {
  slider.value.next()
};

const onVideoPlayed = (index) => {
  // console.log('video start', index)
};

</script>

<style lang="scss">
.dots {
  position: relative;
  display: flex;
  margin-top: -32px;
  z-index: 100;
  padding: 10px 0;
  justify-content: center;
}
.dot {
  border: none;
  width: 16px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 8px;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}
.dot:focus {
  outline: none;
}
.dot.active {
  @apply bg-secondary;
}
</style>